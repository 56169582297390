import React, { useState, useEffect } from "react";
import styles from "./nav.module.scss";
import "./mobile-nav.scss";
import logo from "assets/fountainhouse.svg";
import Link from "components/link";
import useIsMobile from "hooks/useIsMobile";

const links = ["About", "Storywall", "Supporters"];

const Nav = ({color}) => {
  const isMobile = useIsMobile(800);
  return (
    <nav className={`${styles.element} ${color}`}>
      <a
        href="https://fountainhouse.org"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.logo}
      >
        <img src={logo} alt="Fountain House" />
      </a>
      {isMobile ? (
        <MobileNav items={links} />
      ) : (
        <div className={styles.links}>
          {links.map((link) => (
            <a className="MobileNav-links-item" key={link} href={`#${link.replace(" ", "")}`}>
              {link}
            </a>
          ))}
          <a
            className={styles.donate}
            href={"https://give.fountainhouse.org/YourMindMatters"}
            target="_blank"
            rel="noreferrer noopener"
          >Donate</a>
        </div>
      )}
    </nav>
  );
};

export default Nav;

const MenuIcon = ({ active }) => {
  return (
    <div className={`MenuIcon ${active ? "active" : ""}`}>
      <div />
      <div />
      <div />
    </div>
  );
};

const MobileNav = ({ items }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (typeof document.body.styles !== "undefined") {
      document.body.styles.overflow = active ? "hidden" : "";
  
      return () => {
        document.body.styles.overflow = "";
      };
    }
  }, [active]);
  const closeMenu = () => setActive(false);
  return (
    <div className={`MobileNav ${active ? "active" : ""}`}>
      <div className="MobileNav-toggleph">
        <button className="MobileNavToggle" onClick={() => setActive(!active)}>
          <MenuIcon active={active} />
        </button>
      </div>
      {active && items && (
        <ul className="MobileNav-links">
          {links.map((link) => (
            <li key={link}>
              <a
                className="MobileNav-links-item"
                href={`#${link}`}
                onClick={closeMenu}
              >
                {link}
              </a>
            </li>
          ))}
          <li>
            <Link
              className={styles.donate}
              text="Donate"
              hover="green"
              url="https://give.fountainhouse.org/YourMindMatters"
            />
          </li>
        </ul>
      )}
    </div>
  );
};
