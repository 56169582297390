// import React from "react";



export default {
  heading: "",
  navHeading: "Supporters",
  items: [
    {
      heading: "Leadership",
      items: [
        "Howard Appelbaum" ,
        "Jane and Gregory Baecher",
        "Frank Beane",
        "Jeremy L. Goldstein",
        "Lil Phillips",
        "Katie Tozer",
        "Katie Zorn",
      ],
    },
    {
      heading: "Your Mind Matters Supporters",
      items: [
        "Margery and Howard Appelbaum",
        "Jane and Gregory Baecher",
        "Frank Beane",
        "Angela Clofine",
        "Mr. & Mrs. Jack David",
        "Nancy L. Farrell",
        "Tracy Florack",
        "Bill and Wendy Hilburn",
        "Albert Garner and Stephen MacLaughlan",
        "Eleni and Randall Gianopulos",
        "Jeremy L. Goldstein",
        "Carl and Patricia Hagberg",
        "Neil P. Kelly",
        "William Marron",
        "Marc and Laura McKenna",
        "Ina Meibach",
        "Brooke and Robby Mountain",
        "Jennifer Oken",
        "Noelle and Mark Penna",
        "Florence Peyrelongue",
        "Lil Phillips",
        "Arlene Lidsky and Chester B. Salomon",
        "Kathleen E. Shannon",
        "Katie Tozer",
        "Katie Zorn",
        "Anonymous (3)",
      ],
    },
  ],
};
