import React from "react";
import styles from "./link.module.scss";
import { OutboundLink } from "react-ga"


const Link = ({ text, url, hover, className = "" }) => {
  return (
    <OutboundLink
      eventLabel={url}
      className={`${styles.element} hover--${hover} ${className}`}
      to={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {text}
    </OutboundLink>
  );
};

export default Link;