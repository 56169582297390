import React from "react";
import styles from "./section.module.scss";

const Section = ({ className = "", heading, anchor, children, theme }) => {
  return (
    <section id={anchor || heading}>
      <div className={`${styles.element} Section--${theme} ${className}`}>
        <div className={styles.elementInner}>
          <h2 className={styles.heading}>{heading}</h2>
          <div className={styles.inner}>{children}</div>

          { theme ? <aside className={styles.circle}>
            <span  className={styles.span} />
          </aside> : null }
        </div>
      </div>
    </section>
  );
};

export default Section;
