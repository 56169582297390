import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "components/app";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import cssVars from "css-vars-ponyfill";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-165625367-3');
ReactGA.pageview(window.location.pathname + window.location.search);


cssVars();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
