import React from "react";
import styles from "./app.module.scss";
import Nav from "components/nav";
import Hero from "components/hero";
import Supporters from "components/supporters";
// import Sponsors from "components/sponsors";
import Footer from "components/footer";
import Link from "components/link";
import Section from "components/section";

// import image from "assets/image.png"

import scrollHandler from "hooks/scrollHandler";

const sections = [
  { 
    heading: "", 
    theme: "blue", 
    copy: "<p>Your Mind Matters brings together people living with mental illness and supporters to raise awareness for the solutions Fountain House provides, including helping college students derailed by a mental health crisis get back on track with their studies and realize their potential.</p>",
    buttonCopy: "",
    buttonLink: "",
    hover: "white",
    anchor: "",
    navColor: "white"
  },
  { 
    heading: "Youtube Playlist", 
    theme: null, 
    copy: `<iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLSpUSkxCSDz637U4unWiqc38tntJtbgNB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    buttonCopy: "",
    buttonLink: "#",
    hover: "white",
    anchor: "",
    navColor: "black"
  },
  { 
    heading: "Storywall", 
    theme: "white", 
    copy: `
      <p class="small">Join us by tagging @fountainhouse and using the hashtag #yourmindmatters to see your post alongside other mental health advocates!</p>
      <iframe allowfullscreen id="wallsio-iframe" src="https://walls.io/z5w66?nobackground=1&amp;show_header=0" style="border:0;height:800px;width:100%" loading="lazy" title="My social wall"></iframe>`,
    buttonCopy: "",
    buttonLink: "",
    hover: "white",
    anchor: "Storywall",
    navColor: "black"
  },
  { 
    heading: "About Fountain House", 
    theme: "mediumBlue", 
    copy: "<p>For more than 70 years, Fountain House has empowered people living with mental illness to lead the lives they want to live. It has become the most widely replicated community-based model for people living with mental illness, with more than 300 affiliated programs serving over 100,000 people in the US and in more than 30 countries.</p>",
    buttonCopy: "Learn More",
    buttonLink: "https://www.fountainhouse.org",
    hover: "white",
    anchor: "About",
    navColor: "white"
  },
  { 
    heading: "About College Re-Entry", 
    theme: "yellow", 
    copy: "<p>As part of its overall public health approach, Fountain House works on early intervention programming for those living with mental illness. Our College Re-Entry program offers young adults who are forced to leave higher education studies due to early or first episode mental illness, a semester long program of peer engagement, rehabilitation and resilience training, and coping and wellness skills. Nearly 90% of College Re-Entry graduates return back to school within a year, over the first five years of the program. This type of early intervention is especially important as we know that early diagnosis, treatment, and support can fundamentally improve outcomes, redirect trajectories, and stave off the worse health effects.</p>",
    buttonCopy: "Learn More",
    buttonLink: "https://collegereentry.org/",
    hover: "blue",
    anchor: "",
    navColor: "black"
  },
  { 
    heading: "Social Justice Work", 
    theme: "blue", 
    copy: `
      <p>As communities in the United States are reimagining the response to mental health emergencies, Fountain House and a network of Clubhouses around the country are part of a movement to establish a public-health first approach. 
      We envision a future that relieves police of the burden of dealing with mental health care, to minimize interactions that escalate to life-threatening encounters, and where police resources will be freed up to focus on addressing actual crimes. 
      </p>
    `,
    buttonCopy: "Learn More",
    buttonLink: "https://www.fountainhouse.org/care-responders-campaign-ensuring-mental-health-emergencies-receive-right-public-health-response",
    hover: "white",
    anchor: "",
    navColor: "white"
  },
  { 
    heading: "Support", 
    theme: "lightBlue", 
    copy: "<p>We have been living in isolation caused by COVID-19, heightened by systemic injustice. The time to improve access to vital mental health resources is now. <strong>Donate today to join Fountain House’s mental health network that brings community-based solutions to the growing mental health crisis.</strong></p>",
    buttonCopy: "Donate",
    buttonLink: "https://give.fountainhouse.org/YourMindMatters",
    hover: "blue",
    anchor: "Supporters",
    navColor: "black"
  }
];

const App = () => {
  const [color,setColor] = React.useState("black");

  function onScroll() {
    if (typeof window !== "undefined") {
      setColor(window.scrollY > window.innerHeight ? "dark" : "black")
    }
  }

  scrollHandler(onScroll, 200)

  return (
    <div className={styles.element}>
      <Nav color={color} />
      <Hero />
      {sections.map((section) => (
        <Section 
          className={styles.section}
          key={section.heading} 
          heading={section.heading} 
          theme={section.theme} 
          anchor={section.anchor}
        >
          {section.copy ? <div 
            className={section.copy.length > 600 ? styles.small : ""}
            dangerouslySetInnerHTML={{ __html: section.copy }} /> : null}          
          {section.buttonCopy ? 
            <Link 
              url={section.buttonLink} 
              text={section.buttonCopy}
              hover={section.hover}
            /> 
            : null}
        </Section>
      ))}
      <Supporters />
      {/* <Sponsors /> */}
      <aside className={`${styles.bar} Section--yellow`}>
        <p className={styles.copy}>Sign up for the Fountain House monthly newsletter.</p>
        <Link 
          url={"https://www.tfaforms.com/4872546"} 
          text={"subscribe"}
          hover={"blue"}
        /> 
      </aside>
      <Footer />
    </div>
  );
};

export default App;
