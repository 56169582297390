import React from "react";
import Link from "components/link";
import styles from "./hero.module.scss";
// import ymm from "assets/FYHMM_banner.svg";

const Hero = () => {
  return (
    <section id={"Hero"} style={{position: `relative`}}>
      {/* <div className={styles.wrapper}></div> */}
      <div className={styles.element}>
        {/* <div className={styles.embedWrap}>
          <h1 className={styles.header}>Your Mind Matters</h1>
        </div> */}
        <Video />
        {/* <div className={styles.cta}>
          <p className={styles.copy}>Join Us on January 14th at 8 PM EST</p>
          <Link 
            url={"https://www.tfaforms.com/4872546"} 
            text={"Register"}
            hover={"blue"}
          /> 
        </div> */}
      </div>
      <div className={styles.helper}>
        <p>
          If you are experiencing difficulties streaming the video, please try refreshing the page, or <Link url="https://youtu.be/hIshNc8eyd0" text="watch on Youtube" className="simple" />.
        </p>
      </div>
      
      {/* <aside className={styles.circle}>
        <span  className={styles.span} />
      </aside>  */}

    </section>
  );
};

const Video = () => {
  return <div className={styles.videoWrap}>
  {/* 
    <div style="padding:56.25% 0 0 0;position:relative;">
  </div> */}
{/* 
    <iframe 
      src="https://player.vimeo.com/video/500161854" 
      frameBorder="0" 
      title="live stream video"
      allow="autoplay; fullscreen" 
      allowFullScreen 
      className={styles.video}>
    </iframe> */}
    
    <iframe 
      className={styles.video} 
      title="live stream video" 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/hIshNc8eyd0?playlist=hIshNc8eyd0&autoplay=1&showinfo=0&autohide=1&playsinline=1"
      frameBorder="0" 
      // onLoad={(event) => {
      //   if(!isMobile) return
      //   if (event.target) event.target.playVideo()
      //   // console.log(event.target)
      // }}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
      allowFullScreen />
  </div>
}

export default Hero;